import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { requestData, sendData } from 'services/APIClient';
import {
  USER_STORE_PAYMENT_URL,
  USER_STORE_UPDATE_URL,
} from 'services/APIConstants';
import Interceptor from 'services/Interceptor';
import AuthToken, { isLoggedIn } from 'services/Token';

const Save = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [status, setStatus] = useState(false);
  const { item, setFieldErrors } = props;
  const [message, setMessage] = useState('');

  const validateFields = (data) => {
    const errors = {};
    if (!data.alias) errors.alias = 'Alias is required';
    if (!data.city) errors.city = 'City is required';
    if (!data.state) errors.state = 'State is required';
    if (!data.zip_code) errors.zip_code = 'Zip Code is required';
    if (!data.phone) errors.phone = 'Phone is required';
    if (!data.address) errors.address = 'Address is required';
    if (!data.working_days.length || data.working_hours[0] === '') {
      errors.working_days = 'Set at least one working day with hours';
    }
    return Object.keys(errors).length > 0 ? errors : null;
  };
  const [severity, setSeverity] = useState('success');
  const handleSubmit = async (itemObj) => {
    const { id, uuid, index, ...data } = itemObj;
    const errors = validateFields(data);
    if (errors) {
      setFieldErrors(errors);
      setMessage('Please fill in all the required fields.');
      setOpenSnack(true);
      return;
    }
    try {
      setStatus(true);
      await sendData(
        USER_STORE_UPDATE_URL(itemObj.id),
        data,
        AuthToken(),
        'patch',
        isLoggedIn() ? Interceptor : null
      );
      setMessage('Store successfully Updated!');
      setOpenSnack(true);
      setSeverity('success');
      if (!item.is_active) {
        let payment = window.confirm(
          'Would you like to view payment settings?\r\nPress OK to proceed.'
        );
        if (payment) {
          try {
            const resp = await requestData(
              USER_STORE_PAYMENT_URL(uuid.uuid),
              AuthToken(),
              isLoggedIn() ? Interceptor : null
            );
            window.location.replace(resp.data.checkoutLink);
          } catch (err) {
            setMessage('Store successfully Updated!');
          }
        }
      }
      setStatus(false);
    } catch (err) {
      setStatus(false);
      let errorStr = err.data.error;
      const start = errorStr.indexOf("string='") + 8;
      const end = errorStr.indexOf("'", start);
      const errorMessage = errorStr.substring(start, end);
      setFieldErrors([{}]);
      setMessage(errorMessage);
      setOpenSnack(true);
      setSeverity('error');
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {status ? (
        <Paper
          style={{
            height: 50,
            margin: 0,
            cursor: 'pointer',
            backgroundColor: '#808080',
          }}
          variant="outlined"
        >
          <Typography
            variant="h5"
            align="center"
            style={{ lineHeight: '50px' }}
          >
            <CircularProgress />
          </Typography>
          <br />
          <Typography variant="body2" align="center">
            You will be redirected to external page for checkout link setup.
          </Typography>
        </Paper>
      ) : (
        <Button
          variant="outlined"
          style={{
            lineHeight: '50px',
            fontSize: 20,
            width: 300,
            border: '1px solid black',
          }}
          onClick={() => {
            handleSubmit(item);
          }}
        >
          {item.is_active ? 'UPDATE STORE' : 'SAVE & CONTINUE'}
        </Button>
      )}
      <br />
      <br />
      <Typography
        variant="overline"
        align="center"
        style={{ color: '#808080' }}
      >
        {item.is_active
          ? 'This will also update the information on your linked delivery services (if any)'
          : 'You will be redirected to subscribe and onboard the delivery services in an external page.'}
      </Typography>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Save;
